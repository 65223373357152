// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #31ceca;
  --ion-color-primary-rgb: 49, 206, 202;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #2bb5b2;
  --ion-color-primary-tint: #46d3cf;
  --ion-color-primary-darker-1: #27a5a2;
  --ion-color-primary-darker-2: #1d7c79;
  --ion-color-primary-darker-3: #145251;
  --ion-color-primary-darker-3-rgb: 20, 82, 81;
  --ion-color-primary-darker-4: #0a2928;
  --ion-color-primary-lighter-1: #5ad8d5;
  --ion-color-primary-lighter-2: #83e2df;
  --ion-color-primary-lighter-3: #adebea;
  --ion-color-primary-lighter-4: #d6f5f4;

  --ion-color-secondary: #ceca31;
  --ion-color-secondary-rgb: 206, 202, 49;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #b5b22b;
  --ion-color-secondary-tint: #d3cf46;
  --ion-color-secondary-darker-1: #a5a227;
  --ion-color-secondary-darker-2: #7c791d;
  --ion-color-secondary-darker-3: #525114;
  --ion-color-secondary-darker-3-rgb: 82, 81, 20;
  --ion-color-secondary-darker-4: #29280a;
  --ion-color-secondary-lighter-1: #d8d55a;
  --ion-color-secondary-lighter-2: #e2df83;
  --ion-color-secondary-lighter-3: #ebeaad;
  --ion-color-secondary-lighter-4: #f5f4d6;

  --ion-color-tertiary: #ca31ce;
  --ion-color-tertiary-rgb: 202, 49, 206;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #b22bb5;
  --ion-color-tertiary-tint: #cf46d3;
  --ion-color-tertiary-darker-1: #a227a5;
  --ion-color-tertiary-darker-2: #791d7c;
  --ion-color-tertiary-darker-3: #511452;
  --ion-color-tertiary-darker-4: #280a29;
  --ion-color-tertiary-lighter-1: #d55ad8;
  --ion-color-tertiary-lighter-2: #df83e2;
  --ion-color-tertiary-lighter-3: #eaadeb;
  --ion-color-tertiary-lighter-4: #f4d6f5;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #e24b78;
  --ion-color-danger-rgb: 226, 75, 120;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c7426a;
  --ion-color-danger-tint: #e55d86;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;

  // --ion-backdrop-color:
  // --ion-overlay-background-color:
  // --ion-border-color:
  // --ion-box-shadow-color:
  --ion-tab-bar-background: var(--ion-color-primary);
  // --ion-tab-bar-background-focused: var(--ion-color-secondary);
  // --ion-tab-bar-border-color:
  // --ion-tab-bar-color:
  --ion-tab-bar-color-activated: var(--ion-color-dark);
  --ion-toolbar-background: var(--ion-color-primary);
  // --ion-toolbar-border-color:
  // --ion-toolbar-color:
  // --ion-toolbar-color-activated:
  // --ion-toolbar-color-unchecked:
  // --ion-toolbar-color-checked:
  // --ion-item-background:
  // --ion-item-background-activated:
  // --ion-item-border-color:
  // --ion-item-color:
  --ion-placeholder-color: var(--ion-color-step-350);

  --app-selected-icon-text-color: #black;
  --app-deselected-icon-text-color: rgb(102, 102, 102);

  --app-availability-background-default: #3788d8;

  --datepicker-secondary-highlight-background-color: #eee3af;
  --datepicker-secondary-highlight-text-color: #000000;
}
