// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

@import 'shared/styles/variables';

:root {
  --assistance-list-mobile-width-threshold: #{$assistanceListMobileWidthThreshold};
}

* {
  user-select: text;
}
ejs-richtexteditor * {
  user-select: initial;
}
ion-content {
  --keyboard-offset: 0 !important;
}

.popover-content {
  width: auto !important;
}

ion-modal::part(content).sc-ion-modal-md {
  --border-radius: 8px;
}
ion-modal::part(content) {
  --min-width: 95%;
  --min-height: 95%;
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.advanced-toast-modal::part(content) {
  --height: auto;
  --max-width: 80%;
  --min-height: 0;
  --min-width: 0;
  align-self: start;
  margin-top: 1em;
  padding: 0.6em 0.6em 0.6em 1em;
  --border-radius: 4px;
  --box-shadow: 5px 5px var(--ion-color-secondary);
  border: 2px solid var(--ion-color-primary);
}

.assistantAvailabilityModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 600px;
}

.editCredentialsModal::part(content) {
  --max-height: 600px;
  --max-width: 400px;
  --min-height: 0;
  --min-width: 0;
}

.roleAssistantAssistanceEditorModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 600px;
}

.roleAssistantAssistanceFeedbackModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 600px;
}

.assistanceEditorSavePopupModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 30%;
  //--max-height: 30%;
}
.assistanceEditorSavePopupModal::part(backdrop) {
  //--backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.privateMessageViewerModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 600px;
}
.privateMessageViewerModal {
  //--backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.announcementViewerModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 600px;
}
.announcementViewerModal {
  //--backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sendAssistanceOverviewModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  width: 90%;
  //--max-width: 262px; // EJS Calendar width
  //--max-height: 427px; // computed from ejs cal height
}

.progressBarModal::part(content) {
  --min-width: 0;
  --min-height: 0;
  --max-width: 600px;
  --max-height: 200px;
}

.hidden {
  display: none !important;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.justify-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.width-auto {
  width: auto !important;
}

.text-dim {
  color: var(--ion-color-step-200) !important;
}

ion-item ion-label {
  color: var(--ion-color-medium) !important;
}

.pdfViewerModal::part(content) {
  height: 90%;
  width: 90%;
}

.button-icon {
  padding-right: 0.5em;
}

// Ionic override
.ion-page {
  justify-content: flex-start !important;
}

.link {
  cursor: pointer;
}

.cardfooter {
  padding: 0 16px 13px;
}

ion-alert.privateMessageAlert,
ion.alert.calendarRecurrenceAlert {
  --max-width: 80%;
  --max-height: none;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-content-open {
  cursor: auto !important;
}

button.alert-btn-cancel.alert-button {
  color: var(--ion-color-danger);
}

.circle-success {
  background-color: var(--ion-color-success);
  margin-left: 0.5em;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.circle-danger {
  background-color: var(--ion-color-danger);
  margin-left: 0.5em;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-noshrink {
  flex-shrink: 0;
}

.scroll-y,
.scrollVertical {
  overflow-y: scroll;
}
.scroll-x,
.scrollHorizontal {
  overflow-x: scroll;
}

@media screen and (max-width: 490px) {
  .mobile {
    display: initial;
  }
  .desktop {
    display: none;
  }
}
@media screen and (min-width: 491px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: initial;
  }
}

blockquote {
  margin-block-start: 0.4em;
  margin-inline-start: 0.2em;
  padding-left: 0.4em;
  border-left: 1px solid var(--ion-color-primary-lighter-4);
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    white-space: normal !important;
  }
}

.subtitle {
  font-size: 0.7em;
  font-weight: 400;
}

.assistant-select-modal {
  .ionic-selectable-modal {
    ion-content > ion-virtual-scroll > ion-item {
      ion-icon[slot='start'] {
        margin-right: 6px;
      }
    }
  }
}

ion-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  border-radius: 8px;
}

ion-popover ion-datetime {
  background: var(--ion-background-color);
}

ion-select-popover {
  ion-item {
    &.exchangeFilterAvailabilities,
    &.exchangeFilterExchangeAssistances,
    &.exchangeFilterAvailabilitiesExchangeAssistances {
      --min-height: 48px;
      --bullet-width: 1em;
      --bullet-height: 1em;
      --padding-start: calc(25px + var(--bullet-width));
      min-height: var(--min-height);

      &::before {
        position: absolute;
        z-index: 10;
        width: var(--bullet-width);
        height: var(--bullet-height);
        left: 15px;
        top: calc((var(--min-height) - var(--bullet-height)) / 2);
        content: '';
      }
    }
  }

  ion-item.exchangeFilterAvailabilities:before {
    background-color: var(--app-availability-background-default);
  }

  ion-item.exchangeFilterExchangeAssistances:before {
    background-color: var(--ion-color-primary-lighter-2);
  }

  ion-item.exchangeFilterAvailabilitiesExchangeAssistances:before {
    background: linear-gradient(
      45deg,
      var(--app-availability-background-default) 0%,
      var(--app-availability-background-default) 50%,
      var(--ion-color-primary-lighter-2) 50%,
      var(--ion-color-primary-lighter-2) 100%
    );
  }
}

.font-large {
  font-size: large;
}

// ngx-popperjs fix for chrome. See https://github.com/MrFrankel/ngx-popper/issues/180
popper-content {
  .ngxp__container {
    &[aria-hidden='false'] {
      display: block !important;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
