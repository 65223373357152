:root {
  --ion-color-primary: #eeb9c9 !important;
  --ion-color-primary-rgb: 238, 185, 201 !important;
  --ion-color-primary-contrast: #000000 !important;
  --ion-color-primary-contrast-rgb: 0, 0, 0 !important;
  --ion-color-primary-shade: #d1a3b1 !important;
  --ion-color-primary-tint: #f0c0ce !important;
  --ion-color-primary-darker-1: #be94a1 !important;
  --ion-color-primary-darker-2: #8f6f79 !important;
  --ion-color-primary-darker-3: #5f4a50 !important;
  --ion-color-primary-darker-3-rgb: 95, 74, 80 !important;
  --ion-color-primary-darker-4: #302528 !important;
  --ion-color-primary-lighter-1: #f1c7d4 !important;
  --ion-color-primary-lighter-2: #f5d5df !important;
  --ion-color-primary-lighter-3: #f8e3e9 !important;
  --ion-color-primary-lighter-4: #fcf1f4 !important;
}
